@import url(https://fonts.googleapis.com/css?family=Nunito:200,300,regular,500,600,700,800,900,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Sen:regular,500,600,700,800);

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 240 10% 3.9%;
        --card: 0 0% 100%;
        --card-foreground: 240 10% 3.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 240 10% 3.9%;
        --primary: 240 5.9% 10%;
        --primary-foreground: 0 0% 98%;
        --secondary: 240 4.8% 95.9%;
        --secondary-foreground: 240 5.9% 10%;
        --muted: 240 4.8% 95.9%;
        --muted-foreground: 240 3.8% 46.1%;
        --accent: 240 4.8% 95.9%;
        --accent-foreground: 240 5.9% 10%;
        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 0 0% 98%;
        --border: 240 5.9% 90%;
        --input: 240 5.9% 90%;
        --ring: 240 5.9% 10%;
        --radius: 0.5rem;
    }

    .dark,
    :root[class~="dark"] {
        --background: 240 10% 3.9%;
        --foreground: 0 0% 98%;
        --card: 240 10% 3.9%;
        --card-foreground: 0 0% 98%;
        --popover: 240 10% 3.9%;
        --popover-foreground: 0 0% 98%;
        --primary: 0 0% 98%;
        --primary-foreground: 240 5.9% 10%;
        --secondary: 240 3.7% 15.9%;
        --secondary-foreground: 0 0% 98%;
        --muted: 240 3.7% 15.9%;
        --muted-foreground: 240 5% 64.9%;
        --accent: 240 3.7% 15.9%;
        --accent-foreground: 0 0% 98%;
        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 0 0% 98%;
        --border: 240 3.7% 15.9%;
        --input: 240 3.7% 15.9%;
        --ring: 240 4.9% 83.9%;
    }
}

.vertical-scrollbar::-webkit-scrollbar {
    position: relative;
    overflow: hidden;
    width: 3px !important;
    border-radius: 12px !important;
    background-color: #bfdbfe !important;
}

.vertical-scrollbar::-webkit-scrollbar-track {
    width: 3px !important;
    border-radius: 12px !important;
    background-color: #bfdbfe !important;
}

.vertical-scrollbar::-webkit-scrollbar-thumb {
    background-color: #2563eb !important;
    border-radius: 12px !important;
}

.horizontal-scrollbar::scrollbar {
    position: relative;
    overflow: hidden;
    height: 3px !important;
    border-radius: 12px !important;
    background-color: #bfdbfe !important;
}

.horizontal-scrollbar::scrollbar-track {
    height: 3px !important;
    border-radius: 12px !important;
    background-color: #bfdbfe !important;
}

.horizontal-scrollbar::scrollbar-thumb {
    background-color: #2563eb !important;
    border-radius: 12px !important;
}

@keyframes carousel {
    0% {
        background-image: url('./assets/illustrations/chat-bubbles.svg');
    }

    50% {
        background-image: url('./assets/illustrations/conversation.svg');
    }

    100% {
        background-image: url('./assets/illustrations/sitting-in-sofa.svg');
    }

}

@keyframes bounceSlow {

    0%,
    100% {
        transform: translateY(-5%);
    }

    50% {
        transform: none;
    }
}

.animate-bounce-slow {
    animation: bounceSlow 4s ease-in-out infinite;
}